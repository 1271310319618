import { HorizontalScrollView } from "@clipboard-health/ui-components";
import { Stack } from "@mui/material";
import { SelectableBox } from "@src/appV2/redesign/components/SelectableBox";
import { format, isSameDay } from "date-fns";

interface InterviewDateSelectorProps {
  weekDays: Date[];
  selectedDate: Date | undefined;
  shouldDisableDate: (date: Date) => boolean;
  onDateChange: (date: Date) => void;
}

export function InterviewDateSelector(props: InterviewDateSelectorProps) {
  const { weekDays, selectedDate, shouldDisableDate, onDateChange } = props;

  const enabledDates = weekDays.filter((date) => !shouldDisableDate(date));

  return (
    <Stack spacing={2}>
      <HorizontalScrollView>
        {enabledDates.map((date) => {
          const isSelected = selectedDate && isSameDay(date, selectedDate);

          return (
            <SelectableBox
              key={date.toISOString()}
              variant="secondary"
              isSelected={isSelected}
              sx={{
                minWidth: (() => {
                  switch (enabledDates.length) {
                    case 1: {
                      return "100%"; // take full width
                    }

                    case 2: {
                      return "calc(50% - 4px)"; // take half width each
                    }

                    default: {
                      return "calc(40% - 4px)"; // show 2.5 dates
                    }
                  }
                })(),
              }}
              onToggleSelect={() => {
                onDateChange(date);
              }}
            >
              {format(date, "EEE, MMM d")}
            </SelectableBox>
          );
        })}
      </HorizontalScrollView>
    </Stack>
  );
}
