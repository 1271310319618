import { Card, CbhIcon } from "@clipboard-health/ui-components";
import { BaseLink, Text } from "@clipboard-health/ui-react";
import { Box, CardActionArea, CardContent, Stack } from "@mui/material";
import { formatDistanceInMiles } from "@src/appV2/lib/utils/distance";
import { type InterviewWithDetails } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { format } from "date-fns";

import { getPlacementDetailsPath } from "../paths";

export function InterviewCarouselCard({ interview }: { interview: InterviewWithDetails }) {
  const { licensesData } = useDefinedWorker();
  const matchingQualifications = new Set(
    licensesData
      .filter((license) => interview.placement?.workerTypes.includes(license.qualification))
      .map((license) => license.qualification)
  );
  const qualificationText = [...matchingQualifications].join(", ");

  return (
    <Card>
      <CardActionArea
        component={BaseLink}
        to={getPlacementDetailsPath(interview.placementId, interview.placementCandidateId)}
      >
        <CardContent>
          <Stack spacing={6}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={6}>
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={2}
                  sx={{
                    backgroundColor: (theme) => theme.palette.background.secondary,
                    paddingY: 3,
                    paddingX: 4,
                    borderRadius: 3,
                  }}
                >
                  <CbhIcon type="briefcase" size="medium" />
                  <Text semibold variant="body2">
                    Interview
                  </Text>
                </Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                  <CbhIcon type="checkmark" size="medium" />
                  <Text semibold variant="body2">
                    Scheduled
                  </Text>
                </Stack>
              </Stack>
              <Box
                sx={{
                  height: 20,
                  width: 20,
                  borderRadius: "100%",
                  border: (theme) => `1px solid ${theme.palette.border?.primary ?? "gray"}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CbhIcon type="chevron-right" size="small" />
              </Box>
            </Stack>
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Text semibold variant="body1">
                  {format(interview.start, "EEE, MMM d")}
                </Text>
                <CbhIcon type="dot" sx={{ width: 8, height: 8 }} />
                <Text semibold variant="body1">
                  {format(interview.start, "h:mm a")} - {format(interview.end, "h:mm a")}
                </Text>
              </Stack>

              <Stack direction="row" alignItems="center" gap={2}>
                {matchingQualifications.size > 0 && (
                  <>
                    <Text variant="body2">{qualificationText}</Text>
                    <CbhIcon type="dot" sx={{ width: 8, height: 8 }} />
                  </>
                )}
                <Text
                  variant="body2"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textWrap: "nowrap",
                  }}
                >
                  {interview.workplace?.name}
                </Text>
                <CbhIcon type="dot" sx={{ width: 8, height: 8 }} />
                <Text variant="body2">
                  {formatDistanceInMiles(interview.workplace?.distance ?? 0)}
                </Text>
              </Stack>

              <Text variant="body2">Receive $20 to cover transportation costs</Text>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
