import { Text } from "@clipboard-health/ui-react";
import { ButtonBase, Stack } from "@mui/material";

interface InterviewCancelButtonProps {
  isProspectWorkplace: boolean;
  onClick: () => void;
  disabled: boolean;
}

export function InterviewCancelButton(props: InterviewCancelButtonProps) {
  const { isProspectWorkplace, onClick, disabled } = props;
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
      {/* TODO: use TextButton instead, once we can override the text color */}
      <ButtonBase
        disabled={disabled}
        sx={{
          width: "auto",
          borderRadius: 0,
        }}
        onClick={onClick}
      >
        <Text
          variant="body1"
          sx={(theme) => ({
            color: theme.palette.error.light,
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.border?.primary,
            paddingBottom: "2px",
            display: "inline-block",
            "&:hover": {
              borderBottomColor: theme.palette.border?.primary,
            },
          })}
        >
          Cancel {isProspectWorkplace ? "walk-in" : "interview"}
        </Text>
      </ButtonBase>
    </Stack>
  );
}
