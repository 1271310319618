import { BottomSheet } from "@clipboard-health/ui-components";
import { LoadingButton, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { InterviewStatus } from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { capitalize } from "lodash";

import { useUpdateInterview } from "../api/useUpdateInterview";

interface InterviewCancelBottomSheetProps {
  isProspectWorkplace: boolean;
  modalState: UseModalState;
  interviewId: string;
  onCancelInterview: () => Promise<void>;
}

export function InterviewCancelBottomSheet(props: InterviewCancelBottomSheetProps) {
  const { isProspectWorkplace, modalState, interviewId, onCancelInterview } = props;
  const { showErrorToast, showSuccessToast } = useToast();

  const cancelLabel = isProspectWorkplace ? "walk-in interview" : "interview";

  const { mutateAsync: cancelInterview, isLoading: isCancelling } = useUpdateInterview({
    onSuccess: async () => {
      showSuccessToast(`${capitalize(cancelLabel)} cancelled`);
      modalState.closeModal();
      await onCancelInterview();
    },
    onError: () => {
      showErrorToast(`An error occurred cancelling ${cancelLabel}`);
    },
  });

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="horizontal"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          <LoadingButton
            data-testid="confirm-cancellation"
            variant="contained"
            size="large"
            color="primary"
            isLoading={isCancelling}
            disabled={isCancelling}
            onClick={async () => {
              await cancelInterview({
                interviewId,
                attributes: {
                  status: InterviewStatus.CANCELLED,
                },
              });
            }}
          >
            Cancel {cancelLabel}
          </LoadingButton>
        </DialogFooter>
      }
    >
      <Box sx={{ py: 8 }}>
        <Stack spacing={8} alignItems="center" justifyContent="center">
          <Text variant="h3" textAlign="center" sx={{ fontSize: "2.5rem" }}>
            Are you sure you want to cancel this {cancelLabel}?
          </Text>
        </Stack>
      </Box>
    </BottomSheet>
  );
}
