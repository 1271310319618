import { Browser } from "@capacitor/browser";
import { Callout, type CalloutProps } from "@clipboard-health/ui-components";
import { HorizontalScrollView } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { type Theme } from "@mui/material";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import {
  type PlacementCandidate,
  PlacementCandidateStatus,
} from "@src/appV2/redesign/PlacementCandidate/types";
import { type Worker } from "@src/appV2/Worker/api/types";
import { useHistory } from "react-router-dom";

import { CbhFeatureFlag, useCbhFlag } from "../../../FeatureFlags";
import { PLACEMENT_CANDIDATE_PROFILE_MODAL_PATH } from "../../PlacementCandidate/PlacementCandidateModalRoutes";

interface JobsTopButtonsProps {
  placementCandidate: PlacementCandidate;
  worker: Worker;
}

export function JobsTopButtons(props: JobsTopButtonsProps) {
  const { placementCandidate, worker } = props;
  const history = useHistory();

  const passportFeatureFlag = useCbhFlag(CbhFeatureFlag.PLACEMENT_CANDIDATE_RESUME_PARSING, {
    defaultValue: false,
  });

  // Write all the available callouts here, but remember they will not be
  // visible until you add them to the filteredCallouts array, and the
  // isVisible property is undefined or set to true.
  const availableCallouts: Record<
    "updateProfile" | "introVideo" | "resume",
    CalloutProps & { isVisible: boolean }
  > = {
    updateProfile: {
      isVisible: true,
      ...((placementCandidate.status === PlacementCandidateStatus.ONBOARDING && {
        title: "Complete your profile",
        text: "Create a profile to apply to jobs",
      }) || {
        title: "View or edit your job profile",
        text: "View or edit your job profile",
      }),
      iconType: "passkeys",
      sx: (theme: Theme) => ({ backgroundColor: theme.palette.info.light }),
      onClick: () => {
        logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_PROFILE_UPDATE_CLICKED);
        history.push(PLACEMENT_CANDIDATE_PROFILE_MODAL_PATH);
      },
    },
    introVideo: {
      isVisible: !isDefined(placementCandidate.introductionUrl),
      title: "Upload an intro video",
      iconType: "video-outlined",
      sx: (theme: Theme) => ({
        backgroundColor: theme.palette.intent?.success.background,
        color: theme.palette.intent?.success.text,
      }),
      onClick: async () => {
        await Browser.open({
          url: `${environmentConfig.REACT_APP_VIDEOASK_UPLOAD_URL}#worker_id=${worker.userId}&contact_name=${worker.name}&contact_email=${worker.email}`,
        });
        logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_VIDEOASK_VIA_BROWSER_CLICKED, {
          placementCandidateId: placementCandidate.id,
          source: "placements_page_callout",
        });
      },
    },
    resume: {
      isVisible: isDefined(placementCandidate.resume?.passportUrl) && passportFeatureFlag,
      title: "Download your Clipboard Resume",
      iconType: "resume",
      sx: (theme: Theme) => ({ backgroundColor: theme.palette.shift?.priority.background }),
      onClick: () => {
        logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_RESUME_DOWNLOAD_CLICKED);
        window.open(placementCandidate.resume?.passportUrl, "_blank");
      },
    },
  };

  // Define which callouts would be visible depending on the candidate's status
  let filteredCallouts: Array<(typeof availableCallouts)[keyof typeof availableCallouts]> = [
    availableCallouts.updateProfile,
  ];
  if (placementCandidate.status === PlacementCandidateStatus.ACTIVATED) {
    filteredCallouts = [
      availableCallouts.resume,
      availableCallouts.introVideo,
      availableCallouts.updateProfile,
    ];
  } else if (placementCandidate.status === PlacementCandidateStatus.ONBOARDING) {
    filteredCallouts = [availableCallouts.resume, availableCallouts.updateProfile];
  }

  const visibleCallouts = filteredCallouts.filter((callout) => callout.isVisible);

  const stopEventPropagation = (
    event: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
  };

  return (
    <HorizontalScrollView
      stretch
      sx={{ padding: 5 }}
      onTouchStart={stopEventPropagation}
      onTouchEnd={stopEventPropagation}
      onMouseDown={stopEventPropagation}
      onMouseUp={stopEventPropagation}
    >
      {visibleCallouts.map((callout) => (
        <Callout key={callout.title as string} {...callout} />
      ))}
    </HorizontalScrollView>
  );
}
