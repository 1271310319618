import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { SelectableBox } from "@src/appV2/redesign/components/SelectableBox";
import { format } from "date-fns";

export interface InterviewTime {
  start: Date;
  end: Date;
}

interface InterviewTimeSelectorProps {
  timeSlots: InterviewTime[];
  selectedTime: InterviewTime | undefined;
  onTimeSelect: (time: InterviewTime) => void;
}

export function InterviewTimeSelector(props: InterviewTimeSelectorProps) {
  const { timeSlots, selectedTime, onTimeSelect } = props;

  return (
    <Stack spacing={2}>
      <Text variant="subtitle1">Available time slots</Text>
      <Stack
        spacing={3}
        sx={{
          height: 300,
          overflowY: "auto",
          p: 1,
        }}
      >
        {timeSlots.map((time) => (
          <SelectableBox
            key={time.start.toISOString()}
            variant="secondary"
            isSelected={selectedTime?.start.getTime() === time.start.getTime()}
            onToggleSelect={() => {
              onTimeSelect(time);
            }}
          >
            {format(time.start, "h:mm aaa")} - {format(time.end, "h:mm aaa")}
          </SelectableBox>
        ))}
      </Stack>
    </Stack>
  );
}
