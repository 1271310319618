import { Text } from "@clipboard-health/ui-react";
// eslint-disable-next-line no-restricted-imports
import { Card, CardContent, Stack } from "@mui/material";

interface InterviewIncentiveCardProps {
  isProspectWorkplace: boolean;
}

export function InterviewIncentiveCard(props: InterviewIncentiveCardProps) {
  const { isProspectWorkplace } = props;
  const label = isProspectWorkplace ? "walk-in" : "interview";
  return (
    <Card
      variant="elevation"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.tertiary,
      })}
    >
      <CardContent>
        <Stack spacing={7}>
          <Text semibold variant="h5" sx={(theme) => ({ mb: theme.spacing(2) })}>
            Get paid to find work
          </Text>
          <Text sx={(theme) => ({ color: theme.palette.text.secondary })} variant="body2">
            Receive $20 to cover your {label} transportation costs once your {label} is completed.
          </Text>
        </Stack>
      </CardContent>
    </Card>
  );
}
