import { BottomSheet } from "@clipboard-health/ui-components";
import { Title } from "@clipboard-health/ui-react";
import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";

import { DialogFooter } from "../../components/DialogFooter";

interface GeneralInfoBottomSheetProps {
  modalState: UseModalState;
}

export function GeneralPayInfoBottomSheet(props: GeneralInfoBottomSheetProps) {
  const { modalState } = props;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          backgroundColor={(theme) => theme.palette.background.tertiary}
          onClose={() => {
            modalState.closeModal();
          }}
        />
      }
    >
      <DialogContent>
        <Stack spacing={6} mt={4}>
          <Title variant="h2" component="h1">
            Our estimate
          </Title>
          <Stack spacing={5}>
            <Text color={(theme) => theme.palette.text.secondary}>
              Our estimates are based on employer and community-provided information. We gather real
              data on experience, benefits, and shift differentials directly from contributors.
            </Text>
            <Text color={(theme) => theme.palette.text.secondary}>
              We start with a base rate that reflects the years of experience reported by our
              community. You can also choose to forgo a benefits package—instead, gaining a higher
              base rate.
            </Text>
            <Text color={(theme) => theme.palette.text.secondary}>
              To arrive at your final rate, we add any applicable shift differentials. These
              differentials come directly from feedback provided by contributors from our user base.
            </Text>
          </Stack>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
